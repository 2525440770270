import React from "react"
import { Carousel } from "react-bootstrap"
import "./../styles/index-carousel.css"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export default function IndexCarousel() {
  return (
    <Carousel style={{ width: `100%`, height: `93vh` }}>
      <Carousel.Item>
        <CarouselItem
          imgSrc="./carousel/slider1.jpg"
          description="Podstawowa opieka zdrowotna"
          title="Lekarze"
          herf="staff#doctors"
          hrefDescription="Lekarze"
        />
      </Carousel.Item>
      <Carousel.Item>
        <CarouselItem
          imgSrc="./carousel/slider3.jpg"
          description="Podstawowa opieka zdrowotna"
          title="Pielęgniarki"
          herf="staff#nurses"
          hrefDescription="Pielęgniarki"
        />
      </Carousel.Item>
      <Carousel.Item>
        <CarouselItem
          imgSrc="./carousel/slider3.jpg"
          description="Poradnia ginekologiczno-położnicza"
          title="Położne"
          herf="staff#midwives"
          hrefDescription="Położne"
        />
      </Carousel.Item>
      <Carousel.Item>
        <CarouselItem
          imgSrc="./carousel/slider2.jpg"
          description="Poradnia rehabilitacji"
          title="Fizjoterapeuci"
          herf="staff#rehabilitation"
          hrefDescription="Sprawdź"
        />
      </Carousel.Item>
    </Carousel>
  )
  function CarouselItem({ imgSrc, title, description, herf, hrefDescription }) {
    return (
      <div>
        <img
          style={{
            filter: `brightness(70%)`,
            objectFit: `cover`,
            height: `93vh`,
            width: `100%`,
          }}
          src={imgSrc}
          alt={title}
        />
        <div
          style={{
            position: `absolute`,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            width: `100%`,
            textAlign: `center`,
          }}
        >
          <div>
            <h3>{description}</h3>
            <h1>{title}</h1>
            <AnchorLink
              to={herf}
              title={hrefDescription}
              className="section-btn btn btn-default btn-blue"
            />
          </div>
        </div>
      </div>
    )
  }
}
