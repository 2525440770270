import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allMdx(limit: 3, sort: { fields: [frontmatter___date], order: DESC }) {
          edges {
            node {
              excerpt
              fields {
                slug
              }
              frontmatter {
                date(formatString: "DD MMMM YYYY", locale: "pl")
                title
                description
                thumbnail { publicURL }
              }
              body
            }
          }
        }
      }
    `}
    render={data => (
      <section id="news" data-stellar-background-ratio="2.5">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="section-title wow fadeInUp" data-wow-delay="0.1s">
                <h2>Najnowsze wiadomości</h2>
              </div>
            </div>
            {data.allMdx.edges.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug
              return (
                <div class="col-md-4 col-sm-6" key={node.fields.slug}>
                  <div class="news-thumb wow fadeInUp" data-wow-delay="0.4s">
                    <Link to={`/blog${node.fields.slug}`}>
                      <img
                        src={node.frontmatter.thumbnail.publicURL}
                        class="img-responsive"
                        alt=""
                        style={{
                          width: `400px`,
                          height: `300px`,
                          objectFit: `contain`,
                        }}
                      />
                    </Link>
                    <div class="news-info">
                      <span>{node.frontmatter.date}</span>
                      <h3>
                        <Link to={`/blog${node.fields.slug}`}>{title}</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    )}
  />
)
