import React from "react"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import IndexCarousel from "../components/index-carousel"
import News from "../components/news"
import Contact from "../components/contact"

class IndexPage extends React.Component {
  render() {
    return (
      <Layout isMainPage={true}>
        <Helmet>
          <script src={withPrefix("jquery.js")} type="text/javascript" />
          <script src={withPrefix("jquery.sticky.js")} type="text/javascript" />
          <script
            src={withPrefix("jquery.stellar.min.js")}
            type="text/javascript"
          />
          <script src={withPrefix("wow.min.js")} type="text/javascript" />
          <script src={withPrefix("custom.js")} type="text/javascript" />
        </Helmet>
        <SEO
          title="Strona główna"
          keywords={[`bomed`, `NZOZ`, `med`, `medical`, `opieka zdrowotna`]}
        />
        <IndexCarousel />
        <News />
        <Contact />
      </Layout>
    )
  }
}

export default IndexPage
