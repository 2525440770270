import React from "react"
import { FaPhone, FaEnvelope, FaAngleUp } from "react-icons/fa"

export default () => (
  <div>
    <ContactItem
      id="contact"
      title="POZ Bobowa"
      mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2580.1104433396345!2d20.941082315876024!3d49.708722448410136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473debb179de6c35%3A0xab5acc7d7bbc18a2!2sNiepubliczny%20Zak%C5%82ad%20Opieki%20Zdrowotnej%20BOMED%20sp%C3%B3%C5%82ka%20partnerska%20lekarzy%20medycyny%3A%20Lucyny%20Broniek%20i%20Arkadiusza%20Jag%C5%82y!5e0!3m2!1spl!2spl!4v1596538452988!5m2!1spl!2spl"
      telHref="tel:+48183514444"
      telTxt="+48 18 35 14 444"
      mail="administracja@bomed-bobowa.pl"
      companyName="Niepubliczny Zakład Opieki Zdrowotnej BOMED spółka partnerska lekarzy medycyny: Lucyny Broniek i Arkadiusza Jagły"
      address="Świętej Zofii 4"
      city="38-350 Bobowa"
    />
    <ContactItem
      id="contact-filia"
      title="POZ Siedliska"
      mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2579.4479986187184!2d20.98546221587648!3d49.72119304752106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473deaf1652582ad%3A0x65b089efe8c0626b!2sBomed%20s.c.%20NZOZ.%20Broniek%20L.%2C%20Jag%C5%82a%20A.!5e0!3m2!1spl!2spl!4v1596538152364!5m2!1spl!2spl"
      telHref="tel:+48183550700"
      telTxt="+48 18 35 50 700"
      companyName="Niepubliczny Zakład Opieki Zdrowotnej BOMED spółka partnerska lekarzy medycyny: Lucyny Broniek i Arkadiusza Jagły"
      address="Siedliska 300"
      city="38-324 Siedliska"
    />
    <ContactItem
      id="contact-reh"
      title="Rehabilitacja Bobowa"
      mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1722.0814572770707!2d20.940989825537933!3d49.706908352761886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbb410f7207432c7c!2sNiepubliczny%20Zak%C5%82ad%20Opieki%20Zdrowotnej%20BOMED%20sp%C3%B3%C5%82ka%20partnerska%20lekarzy%20medycyny%3A%20Lucyny%20Broniek%20i%20Arkadiusza%20Jag%C5%82y.%20Poradnia%20Rehabilitacyjna%2C%20Pracownia%20Fizjoterapii!5e0!3m2!1spl!2spl!4v1600802797967!5m2!1spl!2spl"
      telHref="tel:+48183514025"
      telTxt="+48 18 35 14 025"
      companyName="Niepubliczny Zakład Opieki Zdrowotnej BOMED spółka partnerska lekarzy medycyny: Lucyny Broniek i Arkadiusza Jagły"
      address="Rynek 2"
      city="38-350 Bobowa"
    />
  </div>
)

function ContactItem({
  id,
  title,
  mapSrc,
  telHref,
  telTxt,
  mail,
  companyName,
  address,
  city,
}) {
  const mailHref = `mailto:${mail}`
  let footerUp
  if (id === "contact-reh") {
    footerUp = (
      <div class="row">
        <div class="col-md-12 border-top">
          <div class="angle-up-btn">
            <a
              href="javascript:smoothScroll('#top')"
              class="smoothScroll wow fadeInUp"
              data-wow-delay="0.8s"
            >
              <FaAngleUp> </FaAngleUp>
            </a>
          </div>
        </div>
      </div>
    )
  }
  let mailContent
  if (mail) {
    mailContent = (
      <p>
        <FaEnvelope class="fa" viewBox="-230 -230 1000 1000" />
        <a href={mailHref}>{mail}</a>
      </p>
    )
  }
  return (
    <section
      id={id}
      class="contact-section"
      data-stellar-background-ratio="2.5"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title wow fadeInUp" data-wow-delay="0.2s">
              <h3>{title}</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12 contact-footer">
            <div class="wow fadeInUp" data-wow-delay="0.4s">
              <div class="footer-thumb">
                <h4 class="wow fadeInUp" data-wow-delay="0.4s">
                  Kontakt
                </h4>
                <div class="contact-info">
                  <p>
                    <FaPhone class="fa" viewBox="-230 -230 1000 1000" />{" "}
                    <a href={telHref}>{telTxt}</a>
                  </p>
                  {mailContent}
                </div>
              </div>
            </div>
            <div class="wow fadeInUp" data-wow-delay="0.4s">
              <div class="footer-thumb">
                <h4 class="wow fadeInUp" data-wow-delay="0.4s">
                  Adres
                </h4>
                <div class="contact-address">
                  <p>{companyName}</p>
                  <p>
                    <strong>{address}</strong>
                  </p>
                  <p>
                    <strong>{city}</strong>
                  </p>
                </div>
              </div>
            </div>

            <div class="wow fadeInUp" data-wow-delay="0.4s">
              <div class="footer-thumb">
                <div class="opening-hours">
                  <h4 class="wow fadeInUp" data-wow-delay="0.4s">
                    Godziny otwarcia
                  </h4>
                  <p>
                    Poniedziałek - Piątek <span>08:00 - 18:00</span>
                  </p>
                  <p>
                    Sobota <span>Zamknięte</span>
                  </p>
                  <p>
                    Niedziela <span>Zamknięte</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <iframe
            class="col-lg-6 col-md-12"
            title="map"
            src={mapSrc}
            frameborder="0"
            style={{ border: 0, minHeight: `400px` }}
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <footer data-stellar-background-ratio="5">
        <div class="container">{footerUp}</div>
      </footer>
    </section>
  )
}
